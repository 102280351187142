import quoteIcon from "../../../assets/img/icons/quote.png";

function TestimonialItem(props) {
  return (
    <div className="testimonial-item testimonial-item--3">
      <div className="testimonial-txt">
        {/* <img src={quoteIcon} alt="ANM Capital" /> */}
        <h5 className="client-name">{props.author}</h5>
        <p>{props.quote}</p>
      </div>
      <div className="testimonial-thumb">
        <img
          src={require("../../../assets/img/" + props.authorThumb)}
          alt="ANM Capital"
        />
      </div>
    </div>
  );
}

export default TestimonialItem;
