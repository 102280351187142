import React from "react";
import SectionTitle from "../UI/SectionTitle";
import ServiceItem from "./ServiceItem";
import ServicesData from "../../data/Services/services";

import serviceTopBg from "../../assets/img/service/service-bg.jpg";

function Services({ classes }) {
  return (
    <div className={`service-area-wrapper ${classes}`}>
      <div
        className="service-area-top"
        style={{
          backgroundImage: `url("${serviceTopBg}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 m-auto text-center">
              <SectionTitle
                marginBottom={0}
                variant="light"
                title="Our Distinct Advantage"
                heading="Our expertise lies in pinpointing unique trading ventures that stand on the brink of expansion but require strategic guidance and resources to realize their full potential. <br> <br> Through our bespoke breakthrough framework, we're able to scale these businesses, thus creating significant value for both investors and founders. This framework is applied through a meticulous process:"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="service-content-area ">
          {ServicesData.map((service) => (
            <ServiceItem
              key={service.id}
              id={service.id}
              title={service.title}
              text={service.shortDesc}
              thumb={service.thumb}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
