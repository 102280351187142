import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";

import highlights from "../../dataNew/highlights.json";

function Blog() {
  return (
    <div className="blog-area-wrapper sm-top">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <SectionTitle
              title="Portfolio Highlights"
              heading="Our investment portfolio encompasses a wide range of trading-related ventures,<br /> each unique yet unified in their pursuit of innovation and excellence."
            />
          </div>
        </div>

        <div className="row mtn-35">
          {highlights.reverse().map((blog) => (
            <BlogItem
              key={blog.id}
              id={blog.id}
              title={blog.title}
              excerpt={blog.excerpt}
              date={blog.publishDate}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
