import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-two";
import Services from "../components/Services";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";
import Testimonial from "../components/Testimonials/home-two";
import Team from "../components/Team/home-two";
import Blog from "../components/Blog";
import Testimonials from "../components/Testimonials/home-one";
import Breakthrough from "../components/About/page";

import ServiceThumb from "../assets/img/about.jpg";

const Portfolio = () => {
  return (
    <Fragment>
      <Header />
      {/* <About /> */}
      {/* <Services />
      <Breakthrough
        title={"Breakthrough"}
        heading={"The ANM Growth Model"}
        content={
          "Our ANM Growth Model is a testament to our commitment to not just scale trading companies but to redefine their market potential entirely. By crafting strategic three-year plans that not only set ambitious goals but also outline clear, actionable paths to achievement, we facilitate a shift towards aggressive growth and market leadership. This model encompasses strategic acquisitions, leadership empowerment, and continuous operational refinement to ensure sustainable success and replicability across our portfolio."
        }
        thumb={ServiceThumb}
      /> */}
      {/* <Team /> */}
      <Blog />
      <Testimonials />
      {/* <Features classes="sp-top sp-bottom" /> */}
      {/* <BrandLogo /> */}
      {/* <Funfact /> */}
      <CallToAction />
      <Footer />
      <MobileMenu />
      <LoginRegister />
      {/* <PageHeader
        bgImg={require("../assets/img/page-header.jpg")}
        title="ABOUT US"
        content="Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <About
        title={"Our Team"}
        heading="Meet Our <br/> Expert Member"
        thumb={ServiceThumb}
        content="<b>Businex</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
      />
      <Services />
      <Features classes={"sm-top"} />
      <Testimonial />
      <Team />
      <BrandLogo />
      <Funfact classes="sp-top" />
      <CallToAction />
      <Footer />
      <LoginRegister />
      <MobileMenu /> */}
    </Fragment>
  );
};

export default Portfolio;
