import React, { Fragment } from "react";

import Header from "../components/Header";
import Slider from "../components/Slider/home-one";
import About from "../components/About/home-one";
import Features from "../components/Features";
import Services from "../components/Services";
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials/home-one";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Breakthrough from "../components/About/page";

import ServiceThumb from "../assets/img/Stones.png";

const HomeOne = () => {
  return (
    <Fragment>
      <Header />
      <Slider />
      {/* <About /> */}
      {/* <Services /> */}
      {/* <Breakthrough
        title={"Breakthrough"}
        heading={"The ANM Growth Model"}
        content={
          "Our ANM Growth Model is a testament to our commitment to not just scale trading companies but to redefine their market potential entirely. By crafting strategic three-year plans that not only set ambitious goals but also outline clear, actionable paths to achievement, we facilitate a shift towards aggressive growth and market leadership. This model encompasses strategic acquisitions, leadership empowerment, and continuous operational refinement to ensure sustainable success and replicability across our portfolio."
        }
        thumb={ServiceThumb}
      /> */}
      {/* <Team /> */}
      {/* <Blog />
      <Testimonials />
      <Features classes="sp-top sp-bottom" /> */}
      {/* <BrandLogo /> */}
      {/* <Funfact /> */}
      <CallToAction />
      <Footer />
      <MobileMenu />
      <LoginRegister />
    </Fragment>
  );
};

export default HomeOne;
